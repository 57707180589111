<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <div v-for="(i, index) in 10" :key="index">
              <b-row>
                <b-col md="8">
                  <ValidationProvider
                    :name="'Value Legend ' + (index + 1)"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group label="Value" label-for="mc-name">
                      <b-form-input
                        type="number"
                        step="0.01"
                        :id="'mc-value' + index"
                        placeholder="Masukkan Value <="
                        v-model="model.legends[index].value"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="1">
                  <b-form-group label="Color" label-for="mc-name">
                    <v-swatches
                      v-model="model.legends[index].color"
                      swatches="text-advanced"
                      show-fallback
                      fallback-input-type="color"
                    ></v-swatches>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </b-col>

                <!-- <b-col md="2">
                  <b-button
                    v-show="index != 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    @click="removeValues(index)"
                    variant="outline-danger"
                    style="margin-top: 24px"
                  >
                    Delete
                  </b-button>
                </b-col> -->
              </b-row>
            </div>

            <!-- <b-row>
              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="addValues"
                  variant="primary"
                  class="float-left mb-1"
                >
                  Add Item
                </b-button>
              </b-col>
            </b-row> -->

            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'indicators' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
.ql-container {
  height: 200px !important;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.vue-swatches__trigger{
  border:2px solid #333333;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import { quillEditor } from "vue-quill-editor";
import { heightTransition } from "@core/mixins/ui/transition";

import VSwatches from "vue-swatches";
// Import the styles too, globally
import "vue-swatches/dist/vue-swatches.css";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    VSwatches,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: "IndicatorLegend",
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "/indicator/",
        fetch: "/indicator/",
        redirect: "indicators",
      },
      model: {
        legends: [],
      },

      tamp:{
        legends:[
          {
            "value":'',
            "color":"#9ccacc"
          },
          {
            "value":'',
            "color":"#8cbbbc"
          },
          {
            "value":'',
            "color":"#7cabac"
          },
          {
            "value":'',
            "color":"#6f9e9d"
          },
          {
            "value":'',
            "color":"#5e8c8b"
          },
          {
            "value":'',
            "color":"#4e7d7c"
          },
          {
            "value":'',
            "color":"#3f6e6c"
          },
          {
            "value":'',
            "color":"#2a5956"
          },
          {
            "value":'',
            "color":"#20504d"
          },
          {
            "value":'',
            "color":"#10413d"
          }
        ]
      },
      indexvalue: 0,
      messages: "",
      getFetch: null,
    };
  },

  mounted() {
    let _ = this;
    _.getData();
  },
  methods: {
    addValues() {
      this.indexvalue = this.indexvalue + 1;
      this.model.legends.push({
        value: null,
        color: null,
      });
    },
    removeValues(index) {
      this.indexvalue = this.indexvalue - 1;
      this.model.legends.splice(index, 1);
    },
    getData() {
      let _ = this;
      axios
        .get(_.config.fetch + this.$route.params.id + "/legend")
        .then((response) => {
          let _ = this;
          //   console.log(response.data.data.rows);
          _.getFetch = response.data.data.rows;
          console.log(_.getFetch.length);
          if (_.getFetch.length < 9) {
            console.log("sini");
            this.model = this.tamp
          } else {
            _.getFetch.forEach((element) => {
              this.model.legends.push({
                value: element.value,
                color: element.color,
              });
            }); 
          }
        });
    },
    save() {
      let _ = this;
      axios
        .put(_.config.api + this.$route.params.id + "/legend" , _.model)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              event: "success",
              title: "Create Success",
              text: res.data.message,
            },
          });
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
        });
    },
  },
};
</script>
